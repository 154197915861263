<template>
  <div>
    <!-- Table Container Card -->
    <filters @filter="fetchDataHandler" />

    <b-card no-body class="mb-0">
      <!-- Search -->
      <div
        class="
          m-2
          d-flex
          align-items-center
          justify-content-end
          flex-column flex-md-row
        "
      >
        <b-form-input
          v-model="searchQuery"
          class="search-input mr-md-1 mb-2 mb-md-0 w-auto"
          :placeholder="$t('search_by_name') || 'Search by name'"
          @input="searchHandler"
        />
        <AppButton
          v-if="userCan('areas-add')"
          variant="primary"
          @click="openAreasForm()"
        >
          <span class="text-nowrap">
            {{ $t("add_new_area") || "Add New Areas" }}
          </span>
        </AppButton>
      </div>
      <b-overlay :show="isLoading" rounded>
        <b-table
          class="position-relative"
          :items="areas"
          hover
          responsive
          striped
          primary-key="id"
          :fields="fields"
          show-empty
          :empty-text="
            $t('no_matching_records_found') || 'No matching records found'
          "
        >
          <template #cell(name_en)="data">
            {{ data.item.name }}
          </template>
          <template #cell(name_ar)="data">
            {{ data.item.name_ar }}
          </template>
          <template #cell(governorate)="data">
            {{
              isRTL ? data.item.governorate.name_ar : data.item.governorate.name
            }}
          </template>
          <template #cell(country)="data">
            {{
              isRTL
                ? data.item.governorate.country.name_ar
                : data.item.governorate.country.name
            }}
          </template>
          <template #cell(edit_delete)="data">
            <div class="d-flex flex-column actions">
              <AppButton
                v-if="userCan('areas-edit')"
                variant="primary"
                class="mb-1"
                @click="openAreasForm(data.item)"
              >
                {{ $t("edit") || "Edit" }}
              </AppButton>
              <AppButton
                v-if="userCan('areas-delete')"
                variant="danger"
                @click="openDeletionWarning(data.item)"
              >
                {{ $t("delete") || "Delete" }}
              </AppButton>
            </div>
          </template>
        </b-table>
        <div class="mx-2 my-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span v-if="areasMeta" class="text-muted">
                {{ $t("total_entries", { total: areasMeta.total }) }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-if="areasMeta && areasMeta.total"
                v-model="areasMeta.current_page"
                :total-rows="areasMeta.total"
                :per-page="areasMeta.per_page"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="changePaginationHandler"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <areasForm
      v-if="userCan('areas-edit') || userCan('areas-add')"
      :id="'area-form'"
      :area="activeArea"
      @hide="hideAreasForm"
    />
    <deleteWarning
      v-if="userCan('areas-delete')"
      :show="isDeletionWarnShown"
      :is-loading="isDeleting"
      :action-label="$t('delete') || 'Delete'"
      :cancel-label="$t('cancel') || 'Cancel'"
      :message="$t('are_you_sure') || 'Are You Sure?'"
      @close="cancelDeletionHandler"
      @delete="deleteGovernate"
    />
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BTable,
  BOverlay,
  BFormInput,
  BPagination,
  BRow,
  BCol,
} from "bootstrap-vue";

import areasForm from "./partials/areasForm.vue";
import deleteWarning from "@/components/UI/deleteWarning.vue";
import filters from "./partials/filters.vue";
import userCan from "@/mixins/UserCan";
import { AppButton } from "@/components/form/index";

export default {
  name: "Areas",
  components: {
    areasForm,
    BRow,
    BCol,
    BFormInput,
    BCard,
    BPagination,
    BButton,
    BTable,
    BOverlay,
    deleteWarning,
    filters,
    AppButton,
  },
  data() {
    return {
      activeArea: null,
      // Pagination
      currentPage: 1,
      perPage: 10,
      totalUsers: 10,

      fields: [
        { key: "edit_delete", label: this.$t("edit_delete") || "Edit/Delete" },
        {
          key: "name_en",
          label: this.$t("name_in_english") || "Name in English",
        },
        {
          key: "name_ar",
          label: this.$t("name_in_arabic") || "Name in Arabic",
        },
        {
          key: "governorate",
          label: this.$t("governorate") || "Governorate",
        },
        {
          key: "country",
          label: this.$t("country") || "Country",
        },
      ],
      isDeletionWarnShown: false,
      searchQuery: "",
      searchQueryTimer: null,
      appliedFilters: {},
    };
  },
  computed: {
    areas() {
      return this.$store.state.areas.data;
    },
    areasMeta() {
      return this.$store.state.areas.meta;
    },
    isLoading() {
      return this.$store.state.areas.isLoading.fetching;
    },
    isDeleting() {
      return this.$store.state.areas.isLoading.deleting;
    },
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
  },
  created() {
    this.fetchGovernates();
  },
  methods: {
    userCan,
    searchHandler(searchText) {
      // debounce
      clearTimeout(this.searchQueryTimer);
      this.searchQueryTimer = setTimeout(() => {
        const searchQuery = { name: searchText };
        this.$store.dispatch("areas/fetchData", searchQuery);
      }, 300);
    },
    openAreasForm(item = null) {
      if (item) this.activeArea = item;
      this.$root.$emit("bv::show::modal", "area-form");
    },
    hideAreasForm() {
      this.activeArea = null;
    },
    fetchGovernates() {
      this.$store.dispatch("areas/fetchData");
    },
    changePaginationHandler(page = 1) {
      this.fetchDataHandler({ page });
    },
    fetchDataHandler(params = null) {
      this.appliedFilters = {
        ...this.appliedFilters,
        page: 1, // reset page to 1 unless page param is passed underneath
        ...params,
      };
      this.$store.dispatch(
        "areas/fetchData",
        JSON.parse(JSON.stringify(this.appliedFilters))
      );
    },

    openDeletionWarning(item) {
      this.isDeletionWarnShown = true;
      this.activeArea = item;
    },
    cancelDeletionHandler() {
      this.isDeletionWarnShown = false;
      this.activeArea = null;
    },
    deleteGovernate() {
      this.$store.dispatch("areas/deleteData", this.activeArea).then(() => {
        this.activeArea = null;
        this.isDeletionWarnShown = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
