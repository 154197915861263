<template>
  <AppForm
    :id="id"
    ref="areaForm"
    class="p-1"
    :title="
      area
        ? $t('edit_form', { name: area.name }) || `Edit ${area.name}`
        : $t('add_new_area') || 'Add New Area'
    "
    :errors-bag="errorsBag"
    :is-loading="isLoading"
    :success-message="successMessage"
    @hide="$emit('hide')"
    @submit.prevent="handleSubmit"
  >
    <AppInput
      v-model="areaData['name']"
      type="text"
      required
      :label="$t('area') || 'Area'"
    />
    <AppInput
      v-model="areaData['name_ar']"
      type="text"
      required
      :label="$t('area_in_arabic') || 'Area in Arabic'"
    />

    <AppSelect
      id="governorate-checkboxes"
      v-model="areaData['governorate']"
      :placeholder="$t('select_governate') || 'Select Governorates'"
      :label="$t('governorate') || 'Governorate'"
      class="required"
      required
      search-store-action="governorates/fetchAllData"
      :options="governorates"
    />
  </AppForm>
</template>

<script>
import { AppInput, AppForm, AppSelect } from "@/components/form/index";

export default {
  components: {
    AppInput,
    AppForm,
    AppSelect,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    area: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      areaData: {},
      successMessage: null,
    };
  },
  computed: {
    isLoading() {
      return (
        this.$store.state.areas.isLoading.creating ||
        this.$store.state.areas.isLoading.updating
      );
    },
    errorsBag() {
      return this.$store.state.areas.errors;
    },
    governorates() {
      return this.$store.state.governorates.data;
    },
  },
  watch: {
    area: {
      handler(val) {
        if (!val) {
          this.areaData = {};
          return;
        }
        this.areaData = {
          ...val,
          governorate: {
            label: this.isRTL ? val.governorate.name_ar : val.governorate.name,
            value: val.governorate.uuid,
          },
        };
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch("governorates/fetchAllData");
  },
  methods: {
    handleSubmit() {
      const actionName = this.area ? "updateData" : "createData";

      this.$store
        .dispatch(`areas/${actionName}`, {
          ...this.areaData,
          governorate: this.areaData.governorate?.value,
        })
        .then(() => {
          // this.$root.$emit("bv::hide::modal", this.id);
          this.$bvModal.hide(this.id);
          this.successMessage =
            this.$t("area_has_been_added_successfully") ||
            "area has been added Successfully";
          this.areaData = {};
        });
    },
    hideForm() {
      // this.$root.$emit("bv::hide::modal", this.id);
      this.$bvModal.hide(this.id);
      this.$refs.areaForm.reset();
      this.successMessage = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
